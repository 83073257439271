export type Filter = {
  title?: string;
  value: string | number;
};

export type FilterCheckbox = Filter & {
  checked: boolean;
};

export type FilterObj = Record<
  string,
  {
    filters: Filter[];
    title?: string;
  }
>;

export type FilterObjCheckbox = Record<
  string,
  {
    filters: FilterCheckbox[];
    title?: string;
  }
>;

export enum PaintingsStatus {
  'for sale' = 'Для продажи',
  'not for sale' = 'Не для продажи',
}

export type DeliveryInfo = {
  index: null | number;
  region: { value: 'string' | null, title?: string };
  city: { value: 'string' | null, title?: string };
  address: string;
  date: null | Date;
  timeFrom: null | Date;
  timeTo: null | Date;
  comment: string;
};
export type UserFormTypes =
  | 'personal'
  | 'educ & qualif'
  | 'public & exhibit'
  | 'concept';

export const keys = [
  'priceFrom',
  'priceTo',
  'heightFrom',
  'heightTo',
  'widthFrom',
  'widthTo',
  'yearFrom',
  'yearTo',
  'status',
  'city'
] as const

export type CatalogKeys = (typeof keys)[number];
